import {memo} from 'react';

import {useSelector} from 'react-redux';

import {selectIsTrackerUrlEmpty, selectOrderSuccessData, selectUserLastOrderScooberJob} from '~/shared/store/selectors';
import {EMPTY_OBJECT} from '~/shared/consts/commonConsts';
import {DeliveryMethods} from '~/shared/consts/restaurantConsts';
import {getTimeRangeLabel, isValidTimeRangeString} from '~/shared/utils/timeRangeHelper';

import useIsShowMap from '../hooks/useIsShowMap';
import useIsShowScooberTrackerOrLink from '../hooks/useIsShowScooberTrackerOrLink';
import VoucherCardOrderComplete from '../VoucherCardOrderComplete';

import {InProgressTracker, TrackerVariants} from './InProgressTracker';
import OrderComplete from './OrderComplete';
import ScooberStatus from './ScooberStatus';
import ScooberTracker from './ScooberTracker';
import ScooberCanceledOrderSection from './ScooberCanceledOrderSection';

const OrderCompleteBody = memo(({
  shouldShowOdDummyTracker,
  isShowScooberMap,
  isShowOrderComplete,
  isScoober,
  isFutureOrder,
  isBarcodeOrder,
  deliveryMethod,
  arrivalOrderDate,
  arrivalOrderTime,
  isCurrentAddressIsDinningRoom,
  isDonation,
  isVoucherCard,
  orderDate,
  orderTotal,
}) => {
  if (isVoucherCard) {
    return <VoucherCardOrderComplete orderTotal={orderTotal} orderDate={orderDate} />;
  }

  if (isShowScooberMap || shouldShowOdDummyTracker) {
    return <InProgressTracker trackerVariant={shouldShowOdDummyTracker ? TrackerVariants.OD : TrackerVariants.SCOOBER} />;
  }

  if (isShowOrderComplete) {
    return (
      <OrderComplete
        isScoober={isScoober}
        isFutureOrder={isFutureOrder}
        isBarcodeOrder={isBarcodeOrder}
        isOrderIsDelivery={deliveryMethod === DeliveryMethods.DELIVERY}
        arrivalOrderDate={arrivalOrderDate}
        arrivalOrderTime={arrivalOrderTime}
        isCurrentAddressIsDinningRoom={isCurrentAddressIsDinningRoom}
        isDonation={isDonation}
      />
    );
  }
  return null;
});

const ScooberAndOrderBlock = ({isDonation, isVoucherCard, shouldShowOdDummyTracker}) => {
  const all = useSelector(selectOrderSuccessData) || EMPTY_OBJECT;
  const {
    address,
    restaurant,
    deliveryMethod: deliveryMethodFromServer,
    isPoolOrder,
    isBarcodeOrder,
    arrivalOrderDate,
    arrivalOrderDateTimeText,
    arrivalOrderTime: _arrivalOrderTime,
    isFutureOrder,
    orderStatus,
    orderDate,
    orderTotal,
  } = all;

  const {status} = useSelector(selectUserLastOrderScooberJob) || EMPTY_OBJECT;

  const isTrackerUrlEmpty = useSelector(selectIsTrackerUrlEmpty);

  const deliveryMethod = deliveryMethodFromServer?.toLowerCase();
  const isScoober = restaurant?.isScoober;
  const isCurrentAddressIsDinningRoom = address?.shiftId;

  const isShowMap = useIsShowMap();
  const {isTracking: isShowScooberTracker, trackerUrl} = useIsShowScooberTrackerOrLink({isUsingEmptyUrl: true});

  const isOrderCancelled = orderStatus === 'Canceled';
  // while the status is undefined, the scoober map should not be presented
  const isShowScooberMap = isScoober && isShowMap && !isOrderCancelled;
  const isShowOrderComplete = isTrackerUrlEmpty || !isScoober || isPoolOrder;

  const arrivalOrderTime = isValidTimeRangeString(arrivalOrderDateTimeText) ?
    getTimeRangeLabel(arrivalOrderDateTimeText) :
    _arrivalOrderTime;

  return (
    <>
      {isShowScooberTracker && !isOrderCancelled && (
        <>
          <ScooberTracker trackerUrl={trackerUrl} />
          <ScooberStatus status={status} isShowScooberTracker={isShowScooberTracker} />
        </>
      )}

      {!isOrderCancelled && (
        <OrderCompleteBody
          arrivalOrderDate={arrivalOrderDate}
          arrivalOrderTime={arrivalOrderTime}
          isShowScooberMap={isShowScooberMap}
          shouldShowOdDummyTracker={shouldShowOdDummyTracker}
          isFutureOrder={isFutureOrder}
          isBarcodeOrder={isBarcodeOrder}
          isScoober={isScoober}
          isCurrentAddressIsDinningRoom={isCurrentAddressIsDinningRoom}
          isDonation={isDonation}
          deliveryMethod={deliveryMethod}
          isShowOrderComplete={isShowOrderComplete}
          isVoucherCard={isVoucherCard}
          orderDate={orderDate}
          orderTotal={orderTotal}
        />
      )}
      {isOrderCancelled && <ScooberCanceledOrderSection />}
    </>
  );
};

export default memo(ScooberAndOrderBlock);
