import styled from 'styled-components';

import {FlexColumnCenter} from '~/shared/theme/FlexLayout';
import {body16Normal, subheader24Bold} from '~/shared/theme/typography';

export const OrderCompleteWrapper = styled(FlexColumnCenter)`
  width: 100%;
`;

export const OrderCompleteHeader = styled.div<{showTracker?: boolean}>`
  ${subheader24Bold}
  line-height: 32px;
  color: ${({theme}) => theme.orderSuccess.body.headerTextColor};
  ${({showTracker}) => showTracker && 'padding-top: 46px;'}
  margin-bottom: 8px;
  text-align: center;
`;

export const OrderCompleteSubheader = styled.div`
  ${body16Normal}
  color: ${({theme}) => theme.orderSuccess.body.headerTextColor};
  line-height: 19px;
  text-align: center;
`;
