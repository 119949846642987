import {useEffect} from 'react';

// @ts-expect-error config needs to be rewritten as ts
import {enableJetFm} from '~/shared/config';
import {createLogger} from '~/shared/logging';
import {trackEvent} from '~/shared/services/analytics';
import {createFeatureManager} from '~/shared/services/feature-management';

import {HOUR} from './consts/commonConsts';
import {eventNames, on} from './events';
import {User} from './store/models';

const logger = createLogger('jetFM');

let fm: any;

const initializeJetFm = async ({userId}: {userId: User['userId']}) => {
  if (fm) {
    logger.warn('jetFm were already initialized');
    return;
  }

  const settings = {
    keyPrefix: '10bis-web',
    cdn: {
      host: 'https://features.api.justeattakeaway.com',
      scope: '10bis-web',
      environment: 'development', // 'production'
      key: '',
      poll: true, // true | false - enable if you want to poll. Ensure interval is set.
      pollInterval: HOUR, // How frequently config should be polled for in ms
    },
    getContext: () => {
      return {
        restrictionsProviders: new Map()
          .set('Country', () => 'IL')
          .set('AppVersion', () => process.env.npm_package_version),
        anonUserId: String(userId),
      };
    },
    onTrack: (key: string, variant: string) => {
      trackEvent('abTestParticipated', {experimentId: key, variant});
    },
    reinitialise: true, // optional but recommended for dynamic configurations, default is false.
    logger: {
      logError: logger.error,
      logWarn: logger.warn,
      logInfo: logger.verbose,
    },
  };

  fm = createFeatureManager(settings);

  await fm.loadFromCdn();

  return fm;
};

export const getExperimentVariant = (featureKey: string) => {
  // currently supports boolean values only.

  // if JetFm is disabled all experiments are disabled and return false by default.
  if (!enableJetFm) {
    return false;
  }

  if (!featureKey) {
    throw new Error('Providing feature key is required');
  }

  if (!fm) {
    logger.error('getExperimentVariant() can\'t be used before initializeJetFm() was invoked');
    throw new Error('Fm isn\'t ready yet.');
  }

  const value = fm.getBooleanValue(featureKey);
  if (value === null) {
    logger.error('fm.getBooleanValue returned with null value for', featureKey);
  }

  return value;
};

// # for users experiments we will pass userId as Identifier
export const useUpdateJetFm = () => {
  useEffect(() => {
    const offLogin = on(eventNames.login, ({user}: {user: User}) => initializeJetFm({userId: user.userId}));
    return () => {
      offLogin();
    };
  }, []);
};

// # for guest experiments we will set a uuid as ID and hold it in the lcoalstorage.
// const _getGuestId = (): string => {
//   const savedGuestId = window.localStorage.getItem(LocalStorageKeys.EXPERIMENTS_UUID);
//   if (!savedGuestId) {
//     const guestId = uuidv4();
//     window.localStorage.setItem(LocalStorageKeys.EXPERIMENTS_UUID, guestId);
    
//     return guestId;
//   }

//   return savedGuestId;
// };

// export const useUpdateJetFm = () => {
//   useEffect(() => {
//     if (!enableJetFm) {
//       return;
//     }

//     const initialize = async () => {
//       const guestId = _getGuestId();
//       await initializeJetFm({userId: guestId});
//     };

//     initialize();
//   }, []);
// };
