import {useEffect, useMemo, useRef, useState} from 'react';

import {useSelector} from 'react-redux';
import styled from 'styled-components';

import {selectOrderSuccessData, selectUserLastOrderScooberJob} from '~/shared/store/selectors';
import {OrderSuccessData} from '~/shared/store/models';
import {getLocalizationService} from '~/shared/services/localisationService';
import {Section} from '~/shared/components/OrderCompleteSections/parts';
import {defaultPaddingCss} from '~/shared/components/OrderCompleteSections/orderSummaryStyle';
import {flexColumn} from '~/shared/theme/FlexLayout';
import {media} from '~/shared/theme/media';
import {isValidTimeRangeString} from '~/shared/utils/timeRangeHelper';

import ScooberStatus from '../ScooberStatus';
import ScooberMap from '../ScooberMap';

import HeaderAndSubheader from './HeaderAndSubheader';
import DummyTracker from './DummyTracker';

const SECOND_IN_MILLIS = 1000;
const MINUTE_IN_MILLIS = 60 * SECOND_IN_MILLIS;
const MAX_TOTAL_ETA_IN_MINUTES = 120;
const TWO_HOURS_IN_MINUTES = 2 * 60;
const TWO_HOURS_IN_MILLIS = MINUTE_IN_MILLIS * TWO_HOURS_IN_MINUTES;

const OrderHeaderSubheaderWrapper = styled(Section)`
  ${defaultPaddingCss}
  ${flexColumn}
  position: relative;
  top: 392px;
  margin-top: 0;
  ${media.minLargeDesktop`
    top: 442px;
  `}
`;

const convertSecondsToMillis = (seconds: number) => seconds * SECOND_IN_MILLIS;

const useTracker = (orderSuccessData: OrderSuccessData | undefined) => {
  // he format:
  // arrivalOrderDate: "30/06/2024" .toLocaleDateString('en-IL')
  // arrivalOrderTime: "13:30"

  // en format:
  // arrivalOrderDate: "6/30/2024" .toLocaleDateString('en')
  // arrivalOrderTime: "1:30 PM"

  const countdownStartingPoint = useRef<Date | null>(null);

  const {orderScheduledDateTime, orderScheduledDelta} = orderSuccessData || {};

  const {currentLanguageKey} = getLocalizationService();
  const [minutesTimer, setMinutesTimer] = useState<number | null>(null);
  const [isToday, setIsToday] = useState<boolean>(false);

  useEffect(() => {
    if (!orderScheduledDateTime) {
      return;
    }

    if (!countdownStartingPoint.current) {
      countdownStartingPoint.current = new Date();
    }

    const localeDateStringLangKey = currentLanguageKey === 'he' ? 'en-IL' : 'en';

    const delta = typeof orderScheduledDelta === 'number' ? orderScheduledDelta : 0;

    const arrivalOrderDateTimeToCompare = new Date(convertSecondsToMillis(orderScheduledDateTime + delta));

    const isOrderDateIsToday =
      arrivalOrderDateTimeToCompare.toLocaleDateString(localeDateStringLangKey) ===
        countdownStartingPoint.current.toLocaleDateString(localeDateStringLangKey);

    if (!isOrderDateIsToday) {
      setIsToday(false);
      return;
    }

    setIsToday(true);

    const isAfter2Hours = Number(arrivalOrderDateTimeToCompare) > Number(new Date(countdownStartingPoint.current.getTime() + (TWO_HOURS_IN_MILLIS)));
    if (isAfter2Hours) {
      return;
    }

    const arrivalOrderTimeInMinutes = (new Date(arrivalOrderDateTimeToCompare).getTime() - countdownStartingPoint.current.getTime()) / (1000 * 60);
    const minutesLeft = Math.round(arrivalOrderTimeInMinutes);
    setMinutesTimer(minutesLeft);
  }, [orderScheduledDateTime, orderScheduledDelta, currentLanguageKey]);

  useEffect(() => {
    const isCountDownRelevant = !minutesTimer || minutesTimer <= 0;
    if (isCountDownRelevant) {
      return;
    }

    const countDownInterval = setInterval(() => setMinutesTimer(_timer => (_timer ? _timer - 1 : _timer)), MINUTE_IN_MILLIS);

    return () => clearInterval(countDownInterval);
  }, [minutesTimer]);

  return {
    isOverTwoHours: !minutesTimer,
    isExceeded: typeof minutesTimer === 'number' && minutesTimer <= 0,
    isMoreThan10MinutesLeft: typeof minutesTimer === 'number' && minutesTimer >= 10,
    isOver20Minutes: typeof minutesTimer === 'number' && minutesTimer <= -20,
    isToday,
    minutesTimer,
  };
};

const getMinutesToRenderInTracker = ({minutesTimer, orderSuccessData, isOverTwoHours}: {
  minutesTimer: number | null;
  orderSuccessData: OrderSuccessData;
  isOverTwoHours: boolean;
}) => {
  if (!isOverTwoHours) {
    return minutesTimer;
  }

  return new Date(convertSecondsToMillis(orderSuccessData.orderScheduledDateTime + orderSuccessData.orderScheduledDelta))
    .toLocaleTimeString('he', {hour: '2-digit', minute: '2-digit'});
};

export enum TrackerVariants {
  OD = 'od',
  SCOOBER = 'scoober'
}

type TrackerVariant = EnumValueType<typeof TrackerVariants>;

const InProgressTracker = ({trackerVariant}: {trackerVariant: TrackerVariant}) => {
  const orderSuccessData = useSelector(selectOrderSuccessData);
  const {status} = useSelector(selectUserLastOrderScooberJob) || {};

  const {minutesTimer, isToday, isOverTwoHours, isExceeded, isMoreThan10MinutesLeft, isOver20Minutes} = useTracker(orderSuccessData);

  const totalEta = useMemo(() => {
    if (!orderSuccessData) {
      return 0;
    }

    const totalEtaInMinutes = (
      orderSuccessData.orderScheduledDateTime +
      orderSuccessData.orderScheduledDelta -
      orderSuccessData.orderPlacementDateTime
    ) / 60;

    if (totalEtaInMinutes >= TWO_HOURS_IN_MINUTES) {
      return MAX_TOTAL_ETA_IN_MINUTES;
    }

    return totalEtaInMinutes;
  }, [orderSuccessData]);

  const arrivalOrderDateTimeText = useMemo(() => {
    if (!orderSuccessData) {
      return '';
    }

    if (isValidTimeRangeString(orderSuccessData.arrivalOrderDateTimeText)) {
      return orderSuccessData.arrivalOrderDateTimeText;
    }

    return orderSuccessData.arrivalOrderTime;
  }, [orderSuccessData]);

  if (!orderSuccessData) {
    return null;
  }

  const showScooberDummyTracker = trackerVariant === TrackerVariants.SCOOBER && isToday && !isExceeded;
  const showOdDummyTracker = trackerVariant === TrackerVariants.OD;
  const showDummyTracker = showOdDummyTracker || showScooberDummyTracker;

  const minutesToRender = showDummyTracker ?
    getMinutesToRenderInTracker({minutesTimer, orderSuccessData, isOverTwoHours}) :
    orderSuccessData.arrivalOrderTime;

  return (
    <>
      <ScooberMap
        restaurantLatitude={orderSuccessData.restaurant.location.latitude}
        restaurantLongitude={orderSuccessData.restaurant.location.longitude}
        userLatitude={orderSuccessData.address.location.latitude}
        userLongitude={orderSuccessData.address.location.longitude}
        showAllMarkers
        isBigIcon
        showUntrackedShippingBadge={showOdDummyTracker}
      />
      <OrderHeaderSubheaderWrapper>
        {showDummyTracker && (
          <DummyTracker
            minutesToRender={minutesToRender}
            totalEta={totalEta}
            isOverTwoHours={isOverTwoHours}
            isOver20Minutes={isOver20Minutes}
            isMoreThan10MinutesLeft={isMoreThan10MinutesLeft}
            isOdTracker={showOdDummyTracker}
          />
        )}
        <HeaderAndSubheader
          isToday={isToday}
          showScooberDummyTracker={showScooberDummyTracker}
          showOdDummyTracker={trackerVariant === TrackerVariants.OD}
          minutesToRender={minutesToRender}
          arrivalOrderDate={orderSuccessData.arrivalOrderDate}
          arrivalOrderTime={orderSuccessData.arrivalOrderTime}
          arrivalOrderDateTimeText={arrivalOrderDateTimeText}
          restaurantName={orderSuccessData.restaurant.restaurantName}
          isExceeded={isExceeded}
          isOverTwoHours={isOverTwoHours}
          isOver20Minutes={isOver20Minutes}
          isMoreThan10MinutesLeft={isMoreThan10MinutesLeft}
        />
      </OrderHeaderSubheaderWrapper>
      <ScooberStatus status={status} mapDisplayed isShowScooberTracker />
    </>
  );
};

export default InProgressTracker;
