import {memo} from 'react';

import styled from 'styled-components';

import {media} from '~/shared/theme/utils';
import Map from '~/shared/components/Maps';
import {secureOpenWindow} from '~/shared/utils/general';
import {body18Bold, body15Bold} from '~/shared/theme/typography';
import {getLocalizationService} from '~/shared/services/localisationService';
import {flexCenterHorizontally} from '~/shared/theme/FlexLayout';

const MapContainer = styled.div`
  ${flexCenterHorizontally}
  height: 400px;
  width: 100%;
  position: absolute;
  left: 0;
  top: 0;
  ${media.minLargeDesktop`
    height: 450px;
 `}
`;

const UntrackedShippingBadge = styled.div`
  background-color: ${({theme}) => theme.colors.feedbackInfoRegular};
  color: ${({theme}) => theme.colors.primaryText};
  position: absolute;
  top: 16px;
  padding: 4px 8px;
  border-radius: 8px;
  ${body15Bold}
  ${media.minLargeDesktop`
    ${body18Bold}
 `}
`;

const onMarkerClick = (latitude, longitude) => {
  secureOpenWindow(`https://www.google.com/maps/search/?api=1&query=${latitude},${longitude}`, '_blank');
};

const ScooberMap = ({
  restaurantLatitude,
  restaurantLongitude,
  userLatitude,
  userLongitude,
  showAllMarkers,
  isBigIcon,
  showUntrackedShippingBadge,
}) => {
  const {t} = getLocalizationService();

  return (
    <MapContainer>
      <Map
        onRestaurantMarkerClick={() => onMarkerClick(restaurantLatitude, restaurantLongitude)}
        onUserMarkerClick={() => onMarkerClick(userLatitude, userLongitude)}
        restaurantLatitude={restaurantLatitude}
        restaurantLongitude={restaurantLongitude}
        userLatitude={userLatitude}
        userLongitude={userLongitude}
        showAllMarkers={showAllMarkers}
        isBigIcon={isBigIcon}
      />
      {showUntrackedShippingBadge && <UntrackedShippingBadge>{t('live_delivery_tracking_unavailable')}</UntrackedShippingBadge>}
    </MapContainer>
  );
};

export default memo(ScooberMap);
