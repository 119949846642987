import {memo} from 'react';

import {getLocalizationService} from '~/shared/services/localisationService';
import {OrderSuccessData} from '~/shared/store/models';
import {getTimeRangeLabel, isValidTimeRangeString} from '~/shared/utils/timeRangeHelper';

import {OrderCompleteHeader, OrderCompleteSubheader, OrderCompleteWrapper} from '../styled';

type HeaderAndSubheaderProps = {
  arrivalOrderDate: OrderSuccessData['arrivalOrderDate'];
  arrivalOrderTime: OrderSuccessData['arrivalOrderTime'];
  arrivalOrderDateTimeText: OrderSuccessData['arrivalOrderDateTimeText'];
  restaurantName: OrderSuccessData['restaurant']['restaurantName'];
  minutesToRender: number | string | null;
  showScooberDummyTracker: boolean;
  isToday: boolean;
  isOverTwoHours: boolean;
  isExceeded: boolean;
  showOdDummyTracker: boolean;
  isOver20Minutes: boolean;
  isMoreThan10MinutesLeft: boolean;
};

export enum OrderCompleteSubheaderVariant {
  FUTURE_NOT_FOR_TODAY,
  OVER_TWO_HOURS,
  BELOW_TWO_HOURS,
  EXCEEDED,
  OD_HAS_MORE_THAN_10_MINUTES_ETA,
  OD_HAS_LESS_THAN_10_MINUTES_ETA,
  OD_COMPLETED_OVER_20_MINUTES,
}

export const SUBHEADERS_MAP = {
  [OrderCompleteSubheaderVariant.FUTURE_NOT_FOR_TODAY]: ['scoober_order_complete_subtitle_future'],
  [OrderCompleteSubheaderVariant.OVER_TWO_HOURS]: ['scoober_order_complete_subtitle_over_two_hours'],
  [OrderCompleteSubheaderVariant.BELOW_TWO_HOURS]: ['scoober_order_complete_subtitle_below_two_hours'],
  [OrderCompleteSubheaderVariant.EXCEEDED]: ['scoober_order_complete_subtitle_exceeded'],
  [OrderCompleteSubheaderVariant.OD_HAS_MORE_THAN_10_MINUTES_ETA]: [
    'delivery_is_provided_by_the_restaurant',
    'for_any_request_please_contact_the_restaurant_directly',
  ],
  [OrderCompleteSubheaderVariant.OD_HAS_LESS_THAN_10_MINUTES_ETA]: [
    'delivery_is_provided_by_the_restaurant',
    'for_any_request_please_contact_the_restaurant_directly',
  ],
  [OrderCompleteSubheaderVariant.OD_COMPLETED_OVER_20_MINUTES]: [
    'delivery_is_provided_by_the_restaurant_couriers_if_it_hasnt_arrived_yet_contact_the_restaurant_directly',
  ],
};

export enum OrderCompleteHeaderVariant {
  OD_HAS_MORE_THAN_10_MINUTES_ETA,
  OD_HAS_LESS_THAN_10_MINUTES_ETA,
  OD_COMPLETED_OVER_20_MINUTES,
  SCOOBER_ORDER_COMPLETE_TITLE_EXCEEDED,
  SCOOBER_ORDER_COMPLETE_TITLE,
}

export const HEADERS_MAP = {
  [OrderCompleteHeaderVariant.OD_HAS_MORE_THAN_10_MINUTES_ETA]: 'the_restaurant_has_your_order',
  [OrderCompleteHeaderVariant.OD_HAS_LESS_THAN_10_MINUTES_ETA]: 'your_order_will_arrive_soon',
  [OrderCompleteHeaderVariant.OD_COMPLETED_OVER_20_MINUTES]: 'enjoy',
  [OrderCompleteHeaderVariant.SCOOBER_ORDER_COMPLETE_TITLE_EXCEEDED]: 'scoober_order_complete_title_exceeded',
  [OrderCompleteHeaderVariant.SCOOBER_ORDER_COMPLETE_TITLE]: 'scoober_order_complete_title',
};

type TranslationKey = string;

export const getSubHeaderKeys = ({
  isToday,
  isOverTwoHours,
  isExceeded,
  showOdDummyTracker,
  isOver20Minutes,
  isMoreThan10MinutesLeft,
}: {
  isToday: boolean;
  isOverTwoHours: HeaderAndSubheaderProps['isOverTwoHours'];
  isExceeded: boolean;
  showOdDummyTracker: boolean;
  isOver20Minutes: boolean;
  isMoreThan10MinutesLeft: boolean;
}): TranslationKey[] => {
  if (showOdDummyTracker) {
    if (isOver20Minutes) {
      return SUBHEADERS_MAP[OrderCompleteSubheaderVariant.OD_COMPLETED_OVER_20_MINUTES];
    }

    if (isMoreThan10MinutesLeft) {
      return SUBHEADERS_MAP[OrderCompleteSubheaderVariant.OD_HAS_MORE_THAN_10_MINUTES_ETA];
    }

    return SUBHEADERS_MAP[OrderCompleteSubheaderVariant.OD_HAS_LESS_THAN_10_MINUTES_ETA];
  }

  if (!isToday) {
    return SUBHEADERS_MAP[OrderCompleteSubheaderVariant.FUTURE_NOT_FOR_TODAY];
  }

  if (isExceeded) {
    return SUBHEADERS_MAP[OrderCompleteSubheaderVariant.EXCEEDED];
  }

  if (isOverTwoHours) {
    return SUBHEADERS_MAP[OrderCompleteSubheaderVariant.OVER_TWO_HOURS];
  }

  return SUBHEADERS_MAP[OrderCompleteSubheaderVariant.BELOW_TWO_HOURS];
};

const getOdHeaderKey = ({
  isOver20Minutes,
  isMoreThan10MinutesLeft,
}: {
  isOver20Minutes: boolean;
  isMoreThan10MinutesLeft: boolean;
}): TranslationKey => {
  if (isOver20Minutes) {
    return HEADERS_MAP[OrderCompleteHeaderVariant.OD_COMPLETED_OVER_20_MINUTES];
  }

  if (isMoreThan10MinutesLeft) {
    return HEADERS_MAP[OrderCompleteHeaderVariant.OD_HAS_MORE_THAN_10_MINUTES_ETA];
  }

  return HEADERS_MAP[OrderCompleteHeaderVariant.OD_HAS_LESS_THAN_10_MINUTES_ETA];
};

const HeaderAndSubheader = ({
  isToday,
  showScooberDummyTracker,
  showOdDummyTracker,
  arrivalOrderDate,
  minutesToRender,
  restaurantName,
  arrivalOrderDateTimeText,
  isExceeded,
  isOverTwoHours,
  isOver20Minutes,
  isMoreThan10MinutesLeft,
}: HeaderAndSubheaderProps) => {
  const {t} = getLocalizationService();

  const subHeaderKeys = getSubHeaderKeys({
    isToday,
    isOverTwoHours,
    isExceeded,
    showOdDummyTracker,
    isOver20Minutes,
    isMoreThan10MinutesLeft,
  });

  const headerKey: string = showOdDummyTracker
  //TODO combine + add tests
    ? getOdHeaderKey({isOver20Minutes, isMoreThan10MinutesLeft})
    : isExceeded
      ? HEADERS_MAP[OrderCompleteHeaderVariant.SCOOBER_ORDER_COMPLETE_TITLE_EXCEEDED]
      : HEADERS_MAP[OrderCompleteHeaderVariant.SCOOBER_ORDER_COMPLETE_TITLE];

  return (
    <OrderCompleteWrapper>
      <OrderCompleteHeader showTracker={showScooberDummyTracker || showOdDummyTracker}>
        {t(headerKey)}
      </OrderCompleteHeader>
      {subHeaderKeys.map(subHeaderKey => (
        <OrderCompleteSubheader key={subHeaderKey}>
          {t(subHeaderKey, {
            arrivalOrderDateTimeText: isValidTimeRangeString(arrivalOrderDateTimeText)
              ? getTimeRangeLabel(arrivalOrderDateTimeText)
              : arrivalOrderDateTimeText,
            minutesToRender,
            restaurantName,
            arrivalOrderDate,
          })}
        </OrderCompleteSubheader>
      ))}
    </OrderCompleteWrapper>
  );
};

export default memo(HeaderAndSubheader);
